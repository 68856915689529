import { render, staticRenderFns } from "./ClientLogos.vue?vue&type=template&id=0bb8d7a8&scoped=true&"
import script from "./ClientLogos.vue?vue&type=script&lang=js&"
export * from "./ClientLogos.vue?vue&type=script&lang=js&"
import style0 from "./ClientLogos.vue?vue&type=style&index=0&id=0bb8d7a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb8d7a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/usr/src/web-www/components/atoms/base-heading/BaseHeading.vue').default,BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,Container: require('/usr/src/web-www/components/atoms/container/Container.vue').default})
